body {
  background-color: #d6d4d0;
  height: 100%;
  width: 100%;
  margin:0;
  padding: 0;
  font-family: Comic Sans MS;
  overflow: hidden; /* Hide scrollbars */


}

.home{
  /* border: 2px solid #000;   */
  height: 100vh;
  font-size: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation-delay: 5s;
  -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 3s; /* Firefox < 16 */

}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.webchat{
  color: #fff;
  font-family: Comic Sans MS;
  background-color: #0223a5;
  border: 3px solid #0223a5;
  border-radius: 50px;
  font-size: 30px;
  cursor: pointer;
  box-shadow: inset 0 0 0 0 #0223a5;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
  width: 550px;
  height: 80px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
}
.webchat:hover {
  box-shadow: inset 0 0 0 50px #fff;
  color: #0223a5;
}

a {
  text-decoration: none;
}

.App {
  height:100%;
  width: 100%;
  position: absolute;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  font-family: Comic Sans MS;
  overflow: hidden; /* Hide scrollbars */

  
}

.App header {
  height: 10%;
  width: 97.5%;
  color: white;
  background: linear-gradient(to right, #0223a5, #84b7e7);
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content:space-between;
  border-top: 10px solid #d6d4d0;
  border-right: 10px solid #d6d4d0;
  border-left: 13px solid #d6d4d0;
  position: relative;
  top: 0px;
  padding-left: 20px;
  padding-left: -15px;
  
}



.App section {
  border: 15px solid #d6d4d0;
  height: 73.3%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
 

}

main {
  border: 2px solid #3f413f; 
  height: 100%;
  width: 100%;
  overflow-y: scroll; 
  font-size: 20px;
  
}
main::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

form {
  height: 10%;
  width: 99%;
  position: fixed;
  bottom: 0px;  
  background-color: #d6d4d0;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content:space-around;
  margin-bottom: 10px;


 
}



input {
  width: 78vw;
  height: 80%;
  border: 2px solid #3f413f;
  line-height: 1.5;
  font-size: 1.5rem;
  background: #f5f5f5;
  color: #2c2c2c;
  text-align: left;
  padding-left: 10px;
  vertical-align: top;
  font-size: 35px;
  font-family: Comic Sans MS;


}



form button {
  width: 16vw;
  height: 84%;
  background-color: #e1e4e2;
  border: solid #686666;
  border-width: 2px 5px 6px 2px;
  font-size: 35px;
  color: #000;
  text-align: center;
  margin-right: 20px;
  margin-left: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Comic Sans MS;



}
button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.si{
  
  height: 100%;
  width: 100%;
  border: 2px solid #3f413f;
  display: flex;
  align-content: center;
  align-items: center;

}

.sign-in{
  color: #000;
  background-color: #ffffff;
  border: 3px solid #000;
  border-radius: 10px;
  font-size: 24px;
  cursor: pointer;
  box-shadow: inset 0 0 0 0 #000000;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
  width: 350px;
  height: 70px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
}
.sign-in:hover {
  box-shadow: inset 0 0 0 50px #000;
  color: #fff;
}

.google{
  height: 50px;
  width: 50px;
  margin: 0 0 0 auto;
}

.sign-out {
  color: #3f413f;
  background-color: #d6d4d0;
  border: 3px solid #3f413f;
  border-color:#3f413f;
  font-size: 25px;
  cursor: pointer;
  box-shadow: inset 0 0 0 0 #3f413f;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.5s;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  font-family: Comic Sans MS;


}
.sign-out:hover {
  box-shadow: inset 0 0 0 50px #3f413f;
  color: #949794;
}



ul, li {
  text-align: left;
  list-style: none;
}

p {
  max-width: 500px;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
}

.message {
  display: flex;
  align-items: center;
  border: 0px solid #000;
  margin-left: 5px;
  margin-right: 10px;
  display: flex;
  font-family: Comic Sans MS;
  font-size: 25px;
  
}

.sent {
  color: red;
}

.sent p {
  color: #000;
  align-self: flex-end;
  
  
  
}
.received{
  color: blue;
}

.received p {
  color: #000000;
}

img {
  width: 49px;
  height: 49px;
  margin: 0px 10px 0px 5px;
}

